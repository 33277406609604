import React from 'react';
import './Talent.scss';

function Talent() {
  return (
  <div class="talent-one talent-selection">
    <div class="talent-choices">
         
    </div>
  </div>
  );
}

export default Talent;
